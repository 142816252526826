<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="10" align-self="center">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="2" class="text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-1"
                v-on="on"
                fab
                x-small
                color="warning"
                @click="passUpdateDialog"
              >
                <v-icon v-text="'mdi-lock'" />
              </v-btn>
            </template>
            <span v-text="'Cambiar contraseña'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense v-if="login.role_id == 7">
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Nombre" :value="data.insured.name" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Apellido paterno"
                    :value="data.insured.first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Apellido materno"
                    :value="data.insured.second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Fecha de nacimiento"
                    :value="data.insured.birthday"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="ID SM" :value="data.insured.enrollment" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Póliza" :value="data.insured.policy" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Número de asegurado"
                    :value="data.insured.credential"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="8">
                  <ViewData
                    label="Nombre de oficina"
                    :value="data.insured.office_address"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Vigencia" :value="data.insured.validity" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Entidad"
                    :value="
                      data.insured.entity_id
                        ? data.insured.entity.entity
                        : 'NO REGISTRADO'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Municipio"
                    :value="
                      data.insured.municipality_id
                        ? data.insured.municipality.municipality
                        : 'NO REGISTRADO'
                    "
                  />
                </v-col>
              </v-row>
              <v-row dense v-if="login.role_id == 8">
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Nombre" :value="data.contractor.name" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="pass_update_dialog" persistent max-width="600">
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Actualizar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="passUpdateDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="pass_update_dialog">
          <v-container>
            <v-form v-on:submit.prevent ref="pass_update_form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Contraseña*"
                    v-model="pass_update.password"
                    :rules="rules.password"
                    :type="show_password ? 'text' : 'password'"
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_password = !show_password"
                    autocomplete="new-password"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn color="warning" @click="passUpdate">
                    <v-icon v-text="'mdi-lock'" left />
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
//import functions
import { show } from "../../requests/pageRequest";
import {
  msgConfirm,
  msgAlert,
  rules,
  URL_API,
  headersToken,
} from "../../control";
import Axios from "axios";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    ViewData,
  },
  data() {
    return {
      id: this.$store.getters.getLogin.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: null,
      pass_update: {},
      pass_update_dialog: false,
      show_password: false,
      rules: rules(),
    };
  },
  methods: {
    getData() {
      this.loading = true;

      show("general/users", this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    },
    passUpdateDialog() {
      this.pass_update = {
        id: parseInt(this.id),
        password: "",
        updated_by_id: this.login.id,
      };

      this.pass_update_dialog = true;
    },
    passUpdateDialogClose() {
      this.pass_update_dialog = false;
      this.show_password = false;
      this.$refs.pass_update_form.resetValidation();
    },
    passUpdate() {
      if (this.$refs.pass_update_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma actualizar la contraseña?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/general/users/passupdate",
                this.pass_update,
                headersToken(this.login.token)
              ).then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.passUpdateDialogClose();
                  this.getData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>